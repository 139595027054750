<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <div class="p-mb-3 p-d-flex p-justify-between">
      <Button v-permission="['product','machineManage','xzhx']" label="新增机械" @click="addOrUpdateFun"/>
      <div class="p-inputgroup" style="width:200px;">
        <InputText @keydown.enter="getList" v-model="name" placeholder="请输入名称检索"/>
        <Button @click="getList" icon="pi pi-search" style="background: #FFFFFF;color: #000000;opacity: 0.25;"/>
      </div>
    </div>
    <DataTable :value="list" :row-hover="true">
      <template #empty>
        没有发现数据。
      </template>
      <Column field="name" header="名称"></Column>
      <Column field="type" header="类型"></Column>
      <Column field="brand" header="品牌"></Column>
      <Column field="mfr" header="厂家/供应商/渠道"></Column>
      <Column field="modal" header="型号"></Column>
      <Column header="操作">
        <template #body="row">
          <span v-permission="['product','machineManage','jxbj']" class="p-mr-3 m-but"
                @click="addOrUpdateFun(row.data)">编辑</span>
          <span v-permission="['product','machineManage','jxsc2']" class="m-but" style="border:none"
                @click="deleteItem(row.data)">删除</span>
        </template>
      </Column>
    </DataTable>
    <Paginator :rows="page.size" :totalRecords="page.total" @page="changePage"
               template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共{totalRecords} 条记录">
      <template #right>
        <label>跳至&nbsp;&nbsp;</label>
        <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                     inputStyle="width: 40px;"/>
        <label>&nbsp;&nbsp;页</label>
      </template>
    </Paginator>
    <add-or-update-machine ref="addOrUpdate" v-if="auDisplay" @close="closeDialog"></add-or-update-machine>
  </div>
</template>

<script>
import AddOrUpdateMachine from "@/views/modules/agriculturaInfo/addOrUpdateMachine";

export default {
  name: "machineManage",
  components: { AddOrUpdateMachine },
  data() {
    return {
      list: null,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      name: null,
      auDisplay: false,
      breadcrumb: {
        home: { label: "生产资料管理", to: "#" },
        items: [{ label: "机械管理", to: "#" }],
      },

    };
  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {
    getList() {
      this.$http(
        "/machine/getList",
        "get",
        {
          current: this.page.current,
          size: this.page.size,
          name: this.name,
        },
        (res) => {
          // console.log(res)
          this.page.total = res.data.total;
          this.list = res.data.records;
        }
      );
    },
    changePage(page) {
      // console.log(page)
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },
    addOrUpdateFun(data) {
      this.auDisplay = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data);
      });
    },
    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除"' + data.name + '"吗?',
        header: "删除本条数据",
        icon: "pi pi-info-circle",
        acceptLabel: "确定",
        accept: () => {
          this.$http("/machine/del/" + data.id, "delete", null, () => {
            this.$confirm.close();
            this.getList();
          });
        },
        rejectLabel: "取消",
        reject: () => {
          this.$confirm.close();
        },
      });
    },
    closeDialog() {
      this.auDisplay = false;
      this.getList();
    },
  },
};
</script>

<style scoped>
.m-but {
  color: #12a25d;
  padding-right: 8px;
  cursor: pointer;
  border-right: 1px solid rgba(216, 216, 216, 1);
}
</style>
